.el-icon- {
  font-family: element-icons !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: baseline;
  display: inline-block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.el-icon-info:before {
  content: "\E61A";
}
.el-icon-error:before {
  content: "\E62C";
}
.el-icon-success:before {
  content: "\E62D";
}
.el-icon-warning:before {
  content: "\E62E";
}
.el-icon-question:before {
  content: "\E634";
}
.el-icon-back:before {
  content: "\E606";
}
.el-icon-arrow-left:before {
  content: "\E600";
}
.el-icon-arrow-down:before {
  content: "\E603";
}
.el-icon-arrow-right:before {
  content: "\E604";
}
.el-icon-arrow-up:before {
  content: "\E605";
}
.el-icon-caret-left:before {
  content: "\E60A";
}
.el-icon-caret-bottom:before {
  content: "\E60B";
}
.el-icon-caret-top:before {
  content: "\E60C";
}
.el-icon-caret-right:before {
  content: "\E60E";
}
.el-icon-d-arrow-left:before {
  content: "\E610";
}
.el-icon-d-arrow-right:before {
  content: "\E613";
}
.el-icon-minus:before {
  content: "\E621";
}
.el-icon-plus:before {
  content: "\E62B";
}
.el-icon-remove:before {
  content: "\E635";
}
.el-icon-circle-plus:before {
  content: "\E601";
}
.el-icon-remove-outline:before {
  content: "\E63C";
}
.el-icon-circle-plus-outline:before {
  content: "\E602";
}
.el-icon-close:before {
  content: "\E60F";
}
.el-icon-check:before {
  content: "\E611";
}
.el-icon-circle-close:before {
  content: "\E607";
}
.el-icon-circle-check:before {
  content: "\E639";
}
.el-icon-circle-close-outline:before {
  content: "\E609";
}
.el-icon-circle-check-outline:before {
  content: "\E63E";
}
.el-icon-zoom-out:before {
  content: "\E645";
}
.el-icon-zoom-in:before {
  content: "\E641";
}
.el-icon-d-caret:before {
  content: "\E615";
}
.el-icon-sort:before {
  content: "\E640";
}
.el-icon-sort-down:before {
  content: "\E630";
}
.el-icon-sort-up:before {
  content: "\E631";
}
.el-icon-tickets:before {
  content: "\E63F";
}
.el-icon-document:before {
  content: "\E614";
}
.el-icon-goods:before {
  content: "\E618";
}
.el-icon-sold-out:before {
  content: "\E63B";
}
.el-icon-news:before {
  content: "\E625";
}
.el-icon-message:before {
  content: "\E61B";
}
.el-icon-date:before {
  content: "\E608";
}
.el-icon-printer:before {
  content: "\E62F";
}
.el-icon-time:before {
  content: "\E642";
}
.el-icon-bell:before {
  content: "\E622";
}
.el-icon-mobile-phone:before {
  content: "\E624";
}
.el-icon-service:before {
  content: "\E63A";
}
.el-icon-view:before {
  content: "\E643";
}
.el-icon-menu:before {
  content: "\E620";
}
.el-icon-more:before {
  content: "\E646";
}
.el-icon-more-outline:before {
  content: "\E626";
}
.el-icon-star-on:before {
  content: "\E637";
}
.el-icon-star-off:before {
  content: "\E63D";
}
.el-icon-location:before {
  content: "\E61D";
}
.el-icon-location-outline:before {
  content: "\E61F";
}
.el-icon-phone:before {
  content: "\E627";
}
.el-icon-phone-outline:before {
  content: "\E628";
}
.el-icon-picture:before {
  content: "\E629";
}
.el-icon-picture-outline:before {
  content: "\E62A";
}
.el-icon-delete:before {
  content: "\E612";
}
.el-icon-search:before {
  content: "\E619";
}
.el-icon-edit:before {
  content: "\E61C";
}
.el-icon-edit-outline:before {
  content: "\E616";
}
.el-icon-rank:before {
  content: "\E632";
}
.el-icon-refresh:before {
  content: "\E633";
}
.el-icon-share:before {
  content: "\E636";
}
.el-icon-setting:before {
  content: "\E638";
}
.el-icon-upload:before {
  content: "\E60D";
}
.el-icon-upload2:before {
  content: "\E644";
}
.el-icon-download:before {
  content: "\E617";
}
.el-icon-loading:before {
  content: "\E61E";
}
.el-icon-loading {
  -webkit-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}
.el-icon--right {
  margin-left: 5px;
}
.el-icon--left {
  margin-right: 5px;
}
