.user-info-bg {
  height: auto;
  overflow: auto;
  background: #f6f6f6 url(../../../../../assets/images/backgroundImage.jpg)
    repeat-x 50% 50%;
  background-size: cover;
}
.user-info-header {
  text-align: center;
  position: relative;
  background: #00b2e2;
  font-size: 1.2rem;
  padding: 10px 50px;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.user-info-content {
  color: #fff;
  max-width: 750px;
  margin: 0 auto;
}

.user-info-wrap {
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.4);
  padding: 15px 30px;
}

.user-info-label-title {
  display: inline-block;
  width: 90px;
  margin-right: 15px;
  color: hsla(0, 0%, 100%, 0.7);
}

.user-info-form-item {
  margin-bottom: 0 !important;
  padding-bottom: 50px !important;
}

.user-info-name-form {
  padding: 15px 30px;
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.4);
}

.user-info-label {
  color: #fff;
  text-align: right;
  float: left;
  font-size: 16px;
  line-height: 40px;
  padding: 0 12px 0 0;
  box-sizing: border-box;
}

.user-info-input {
  line-height: 40px;
  position: relative;
  font-size: 14px;
}

.btn-wrap {
  margin-top: 100px;
  margin-bottom: 20px;
  text-align: center;
  width: 100%;
  max-width: 750px;
}

.user-info-input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  cursor: pointer;
}

.confirm-btn {
  font-size: 1.6rem;
  width: 80%;
  color: #555;
  border: #58595b;
  height: 55px;
  border-radius: 30px;
  transition: all 0.3s ease;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  background: #fff;
  text-align: center;
  box-sizing: border-box;
  outline: 0;
  margin: 0;
  font-weight: 500;
}

.cancel-btn {
  font-size: 1.6rem;
  width: 80%;
  border: #58595b;
  height: 55px;
  color: #fff;
  border-radius: 30px;
  transition: all 0.3s ease;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  background: #58595b;
  text-align: center;
  outline: 0;
  margin: 0;
  font-weight: 500;
  padding: 12px 20px;
}

@media screen and (max-width: 600px) {
  .user-info-label-title {
    width: 45px;
  }
}
