:root {
  --blue: #007bff;

  --indigo: #6610f2;

  --purple: #6f42c1;

  --pink: #e83e8c;

  --red: #dc3545;

  --orange: #fd7e14;

  --yellow: #ffc107;

  --green: #28a745;

  --teal: #20c997;

  --cyan: #17a2b8;

  --white: #fff;

  --gray: #6c757d;

  --gray-dark: #343a40;

  --primary: #00b5e2;

  --secondary: #bbb;

  --success: #28a745;

  --info: #17a2b8;

  --warning: #ffc107;

  --danger: #dc3545;

  --light: #f8f9fa;

  --dark: #343a40;
}

body {
  margin: 0;
  font-family: Helvetica Neue !important;
  font-size: 14px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.text-center {
  text-align: center;
}

.cursor-pointer {
  cursor: pointer;
}

.version {
  display: none;
  position: relative;
  margin-top: 3rem;
  bottom: 5px;
  width: 100%;
  text-align: center;
  font-size: 1rem;
  opacity: 0.7;
  color: #fff;
  pointer-events: none;
}
