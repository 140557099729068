.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  margin: 0 auto;
  border-radius: 25px;
  overflow: hidden;
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  position: relative;
  background: #f3f3f3;
  width: 400px;
  min-width: 300px;
}

.modal-body {
  margin: 0;
  padding: 20px;
  text-align: center;

  p {
    color: #464646;
    font-size: 1.6rem;
    padding: 1rem 0 0 0;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
  }
}

.popup-body {
  color: #000;
  font-size: 26px;
  text-align: center;
  padding: 5px !important;
}
.popup-footer {
  width: 100%;
  height: 58px;
  text-align: center;
  color: #007aff;
  line-height: 55px;
  font-size: 22px;
  cursor: pointer;
  margin: 0.25rem;
}

.modal-footer {
  padding-top: 0;
  text-align: center;
  border-top: 1px solid #afafaf;
  height: 58px;
}
.footer {
  float: left;
  height: 58px;
  color: #007aff;
  line-height: 58px;
  font-size: 22px;
  cursor: pointer;
  transition: all 0.3s ease;
}
.ok-btn-footer {
  width: 50%;
}

.cancel-btn-footer {
  width: 40%;
  border-right: 1px solid #afafaf;
}

.modalHeader {
  color: #464646;
  font-size: 1.6rem;
  padding: 1rem 0;
}
.modalBody {
  color: #464646;
  font-size: 28px;
  margin-top: 10px;
  text-align: center;
}
.form-input-error-display-modal {
  color: #f56c6c;
  font-size: 12px;
  line-height: 1;
  padding-top: 4px;
  top: 100%;
  left: 0;
  display: flex;
}

.form-input-control.is-valid {
  border-color: #67c23a !important;
}
.form-input-control.is-invalid {
  border-color: #f56c6c !important;
}

p.addText {
  font-size: 14px;
  line-height: 1.4;
  color: #666;
}

.modal-body p.has-owner {
  color: #464646;
  font-size: unset;
  padding: 0;
  display: block;
}

@media screen and (max-width: 500px) {
  .popup .modal-mask .modal-wrapper .modal-container {
    width: 100px;
  }
}
@media screen and (min-width: 320px) and (max-width: 425px) {
  .popup .modal-mask .modal-wrapper .modal-container {
    width: 100px;
  }
  .modal-container {
    width: 240px;
    min-width: 250px;
    height: 450px;
  }
}

@media screen and (min-width: 425px) and (max-width: 575px) {
  .modal-container {
    width: 320px;
    min-width: 350px;
    height: 450px;
  }
}
