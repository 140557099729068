.selectFrame {
  position: relative;
  text-align: center;
  height: 100vh;
}

.page-top {
  background: (linear, left top, left bottom, from(#0088ac), to(#01c8fe));
  background: linear-gradient(#0088ac, #01c8fe);
}

.page-top {
  .header {
    padding: 1.5rem 0;
    color: #fff;
    font-size: 16px;
    margin: 0 60px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.page-top {
  .setting {
    width: 60px;
    position: absolute;
    top: 1rem;
    right: 0;
    z-index: 90;
    cursor: pointer;
  }
}

.page-top {
  .setting .img {
    height: 2rem;
    width: 2rem;
  }
}

.page-top {
  .setting-menu {
    position: absolute;
    list-style: none;
    background: #fff;
    border-radius: 15px;
    top: 1.29rem;
    right: 1.1rem;
    padding: 0.5rem 0;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.15);
    box-sizing: border-box;
    width: 220px;
    font-size: 16px;
  }
}

.page-top {
  .setting li {
    padding: 0 18px;
    font-size: 14px;
    color: #555;
    cursor: pointer;
    line-height: 35px;
    position: relative;
    z-index: 99;
  }
}

ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 40px;
}

.userGuideBtn {
  width: 60px;
  position: absolute;
  top: 1rem;
  left: 0;
  z-index: 90;
}

.userGuideBtn img {
  width: 2rem;
  height: 2rem;
}

.framebox {
  position: relative;
  min-height: 100px;
}

.framebox {
  .frame {
    position: relative;
    overflow: hidden;
    padding: 9px 60px;
    margin: 0 0;
    text-align: left;
  }
}

.framebox {
  .blackFrame {
    display: inline-block;
    position: relative;
    margin-right: 25px;
    font-size: 14px;
    width: 108px;
  }
}

.framebox {
  .framePhoto {
    position: relative;
  }
}

.framebox {
  .blackFrame img {
    width: 100%;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  }
}

div .frame-style {
  display: flex;
  justify-content: center;
}

.framebox {
  .frameInbox {
    position: relative;
  }
}

div .slick-track {
  display: flex !important;
  align-items: flex-end !important;
}

.slick-track {
  margin-left: 0 !important;
}

.slick-prev,
.slick-next {
  color: #000000 !important;
}

.slider-action-btn {
  height: 30px;
  width: 30px;
  padding: 5px;
  background: transparent;
  border: none !important;
  box-shadow: none !important;
}
.tabs-top {
  min-height: 50vh;
}
.tabs-header {
  position: relative;
}
.tabs-nav {
  overflow: hidden;
  position: relative;
}

.nav-link {
  color: #01c8fe;
}

.nav-item {
  margin-left: 10px;
}
.dashboard-btn {
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  background-color: #bbb;
  border: 1px solid #dcdfe6;
  color: #fff;
  text-align: center;
  box-sizing: border-box;
  outline: 0;
  margin: 0;
  transition: 0.1s;
  font-weight: 500;
  padding: 12px 20px;
  font-size: 14px;
  border-radius: 4px;
}
.buttonArea {
  clear: both;
  position: absolute;
  right: 0;
  margin-right: 30px;
  z-index: 10;
  margin-top: 5px;
}

.dashboard-btn {
  .buttonArea {
    .dashboard-btn-size {
      margin-left: 0.6rem;
      border-radius: 50px !important;
      padding: 12px 23px !important;
    }
  }
}

.image-list {
  display: flex;
  position: relative;
  flex-wrap: wrap;

  .image-container {
    margin: 18px 12px;
    height: 100px;
    position: relative;

    input.dashboard-img-checkbox {
      position: absolute;
      top: 0;
      right: 0;
    }

    img.dashboard-img {
      height: 100px;
      width: auto;
      margin: 0 !important;
    }
  }
}

.img-checkbox {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 1px;
  padding: 0.5rem;
  border: 2px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}
.btn-primary.dropdown-toggle {
  color: transparent;
  background-color: transparent !important;
  border-color: transparent !important;
}

.dashboard-btn-primary {
  color: #fff;
  background-color: #00b5e2 !important;
  border-color: #07add6;
}

.dashboard-title {
  line-height: 2.4em !important;
  margin-bottom: 1rem !important;
  color: rgba(0, 0, 0, 0.5);
}
.set-selected-image {
  width: 160px;
  height: 120px;
  float: left;
  margin-left: 20px;
}

.dashboard-img-checkbox {
  height: 18px;
  width: 20px;
  position: relative;
  float: left;
  top: 10px;
  border: 2px solid;
  border-radius: 5px;
}

.nav-tabs .nav-link {
  margin-top: -20px;
  background: 0 0;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.dashboard-btn-selectAll {
  border-radius: 50px;
  padding: 10px 12px;
  font-family: sans-serif;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 10px;
  margin-left: 152px;
}

@media screen and (max-width: 2560px) {
  .img-checkbox {
    height: 12px !important;
    width: 12px !important;
  }
  .dashboard-img {
    height: 100px;
    width: auto;
    margin-left: 22px;
    margin-top: 25px;
  }
  .dashboard-img-checkbox {
    height: 18px;
    width: 20px;
    position: relative;
    float: left;
    top: 10px;
    border: 2px solid;
    border-radius: 5px;
  }
  .buttonArea-mobile {
    display: none;
  }
}
@media screen and (max-width: 2000px) {
  .img-checkbox {
    height: 20px;
    width: 20px;
  }
  .buttonArea-mobile {
    display: none;
  }
}

@media screen and (max-width: 1860px) {
  .img-checkbox {
    height: 20px;
    width: 20px;
  }
  .buttonArea-mobile {
    display: none;
  }
}

@media screen and (max-width: 1440px) {
  .img-checkbox {
    height: 12px !important;
    width: 12px !important;
  }
  .dropdown {
    position: relative;
    float: right !important;
  }
  .buttonArea-mobile {
    display: none;
  }
}
@media screen and (max-width: 1280px) {
  .dropdown {
    position: relative;
    float: right !important;
  }
  .buttonArea-mobile {
    display: none;
  }
}

@media screen and (max-width: 1190px) {
  .dropdown {
    position: relative;
    float: right !important;
  }
}

@media screen and (max-width: 1024px) {
  .framebox {
    .frame {
      margin: 0 0;
    }
  }
  .userGuideBtn {
    width: 60px;
  }
  .page-top {
    .setting {
      .settings {
        background: #fff;
        list-style: none;
        border-radius: 15px;
        padding: 0.5rem 0;
        right: 0.3rem;
        top: 2.3rem;
        position: absolute;
        inset: 0 0 auto auto;
        transform: translate(0px, 46px);
        width: 220px;
        text-align: center;
      }
    }
  }
  .frame-style {
    font-size: 11px;
  }
  .img-checkbox {
    height: 13px !important;
    width: 13px !important;
  }
  .dashboard-btn {
    position: fixed;
    bottom: 0;
    z-index: 10;
    background: #f5f7fa;
    padding: 0.5rem;
    display: flex;
    width: 100%;
    justify-content: center;
  }
  .buttonArea-mobile {
    position: fixed;
    bottom: 0;
    z-index: 10;
    padding: 0.5rem;
    display: flex;
    width: 100%;
    justify-content: center;
  }
  .buttonArea {
    display: none;
  }
  .dashboard-btn-mobile {
    margin-left: 8px;
    margin-right: 10px;
    border-radius: 50px;
    overflow: hidden;
    background-color: #bbb;
    border: 1px solid #dcdfe6;
    color: #fff;
    font-size: 17px;
    padding: 4px;
  }
  .dropdown {
    position: relative;
    float: right !important;
  }
}

@media screen and (max-width: 770px) and (max-width: 825px) {
  .framebox {
    .frame {
      margin: 0 0;
    }
  }
  .frame-style {
    font-size: 13px;
  }
  .img-checkbox {
    height: 12px !important;
    width: 12px !important;
  }
  .dropdown {
    position: relative;
    float: right !important;
  }
  .buttonArea-mobile {
    position: fixed;
    bottom: 0;
    z-index: 10;
    background: #f5f7fa;
    padding: 0.5rem;
    display: flex;
    width: 100%;
    justify-content: center;
  }
  .buttonArea {
    display: none;
  }
  .dashboard-btn-mobile {
    margin-left: 8px;
    margin-right: 10px;
    border-radius: 50px;
    overflow: hidden;
    background-color: #bbb;
    border: 1px solid #dcdfe6;
    color: #fff;
    font-size: 17px;
    padding: 4px;
  }
}

@media screen and (max-width: 575px) {
  .framebox {
    .frame {
      margin: 0 0;
    }
  }
  .frame-style {
    font-size: 11px;
  }
  .img-checkbox {
    height: 13px !important;
    width: 13px !important;
  }
  .dropdown {
    position: relative;
    float: right !important;
  }
  .buttonArea-mobile {
    position: fixed;
    bottom: 0;
    z-index: 10;
    background: #f5f7fa;
    padding: 0.5rem;
    display: flex;
    width: 100%;
    justify-content: center;
  }
  .buttonArea {
    display: none;
  }
  .dashboard-btn-mobile {
    margin-left: 8px;
    margin-right: 10px;
    border-radius: 50px;
    overflow: hidden;
    background-color: #bbb;
    border: 1px solid #dcdfe6;
    color: #fff;
    font-size: 17px;
    padding: 4px;
  }
}

@media screen and (max-width: 420px) {
  .framebox {
    .frame {
      margin: 0 0;
    }
  }
  .frame-style {
    font-size: 10px;
  }
  .dropdown {
    position: relative;
    float: right !important;
  }
  .buttonArea-mobile {
    position: fixed;
    bottom: 0;
    z-index: 10;
    background: #f5f7fa;
    padding: 0.5rem;
    display: flex;
    width: 100%;
    justify-content: center;
  }
  .buttonArea {
    display: none;
  }
  .dashboard-btn-mobile {
    margin-left: 8px;
    margin-right: 10px;
    border-radius: 50px;
    overflow: hidden;
    background-color: #bbb;
    border: 1px solid #dcdfe6;
    color: #fff;
    font-size: 17px;
    padding: 4px;
  }
}

@media screen and (min-width: 320px) and (max-width: 360px) {
  .frame-style {
    font-size: 13px;
  }
  .dashboard-btn {
    overflow: hidden;
    padding: 0.7rem;
  }
  .img-checkbox {
    height: 10px;
    width: 10px;
  }
  .buttonArea-mobile {
    position: fixed;
    bottom: 0;
    z-index: 10;
    background: #fff;
    padding: 0.5rem;
    display: flex;
    width: 100%;
    justify-content: center;
  }
  .buttonArea {
    display: none;
  }
  .dashboard-btn-mobile {
    margin-left: 8px;
    margin-right: 10px;
    border-radius: 50px;
    overflow: hidden;
    background-color: #bbb;
    border: 1px solid #dcdfe6;
    color: #fff;
    font-size: 17px;
    padding: 4px;
  }
}

.toggle:focus {
  box-shadow: 0 0 0 0.25rem rgb(49 132 253 / 4%);
}
