.invite-user-page {
  height: 205vh;
  min-height: 100%;
  background: #f6f6f6;
  padding-bottom: 350px;
}

.invite-user-header {
  text-align: center;
  position: relative;
  background: #00b2e2;
  font-size: 1.2rem;
  padding: 10px 50px;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.invite-user-content {
  max-width: 600px;
  margin: auto;
}

.invite-user-form-item {
  margin-bottom: 0 !important;
  padding-bottom: 25px !important;
}

.inviteUser-form-item-content {
  line-height: 40px;
  position: relative;
  font-size: 14px;
}

.invite-user-textArea {
  display: inline-block;
  width: 100%;
  vertical-align: bottom;
  font-size: 14px;
}

.form-input-control.invite-user-textArea__content {
  position: relative;
  font-size: inherit;
  display: block;
  width: 100%;
  height: auto !important;
  color: #555 !important;
  background-color: #fff;
  background-image: none;
  border-radius: 4px !important;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  line-height: 1.5;
  outline: 0;
  padding: 5px 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.invite-user-frames {
  position: relative;
  height: auto;
  display: flex;
}

.invite-user-area {
  flex: 5;
  white-space: nowrap;
  overflow: auto;
  text-align: center;
  padding: 1rem 2rem;
}

.invite-user-frameData {
  width: 90px;
  min-height: 80px;
  position: relative;
  display: inline-block;
  margin: 0 15px;
  font-size: 14px;
}

.choose-flag {
  position: absolute;
  top: 0;
  right: 0;
  width: 15px;
  height: 15px;
  border: 2px solid #fff;
  border-radius: 50%;
}
.invite-user-owner-text {
  text-align: center;
  color: rgb(102, 102, 102);
  line-height: 1.7;
}

.invite-btn {
  width: 60%;
  min-width: 10rem;
  border-radius: 50px;
  opacity: 0.6;
  background-color: #bbb;
  border-color: #aaa !important;
  color: #fff;
  padding: 12px 23px;
  font-size: 18px;
}
.alert-message {
  position: relative;
  padding: 5vh 1rem 5vh 2em;
  text-align: center;
}

.alert-message > dt {
  font-size: 1.6rem;
  font-weight: 400;
  margin-bottom: 1rem;
}
.text-sending {
  color: #00b2e2;
}

.error-display {
  color: #f56c6c;
}
.invite-user-ok-btn {
  background-color: #00b2e2;
  border: #00b2e2;
  width: 35%;
  height: 55px;
  color: #fff;
  border-radius: 30px;
  font-size: 22px;
  transition: all 0.3s ease;
  margin-top: 45px;
}

.form-input-control.invite-user-textArea__content.is-valid {
  border-color: #67c23a !important;
}

@media screen and (max-width: 600px) {
  .invite-user-content {
    padding: 1rem;
  }
  .invite-user-content {
    max-width: 600px;
    margin: auto;
  }
  .invite-user-owner-text {
    margin-top: 50px !important;
  }
}

@media screen and (min-width: 800px) and (max-width: 1370px) {
  .framebox {
    height: 300px !important;
  }
}
