.display-item {
  display: none;
}

.mui-circular-progress {
  color: #01c8fe !important;
}

.custom-tooltip {
  display: block;
  margin: 0 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}
