.contact-help-page {
  height: auto;
  min-height: 100%;
  background: #f6f6f6;
  text-align: center;
}
.contact-help-header {
  position: relative;
  background: #00b2e2;
  font-size: 1.2rem;
  padding: 10px 50px;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.contact-help-header {
  .contact-help {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 85%;
    height: 100%;
    padding: 0.9rem 1rem;
  }
}
