.box {
  height: 100vh;
  overflow: auto;
  background: #1fa7d1 url(../../../assets/images/backgroundImage.jpg) repeat-x
    50% 50%;
  background-size: cover;
}

.header {
  padding: 10vh 5vw;
}

.form {
  max-width: 400px;
  margin-left: auto;
  margin-top: 50px;
  margin-right: auto;
  width: 75%;
}
.forgot-password-form {
  margin: 15px auto 25px;
  max-width: 400px;
  width: 75%;
}

.title {
  font-size: 1.6rem;
  text-align: center;
  color: #fff;
  justify-content: center;
  margin: 7px 0 30px;
}

.form-group {
  margin-bottom: 0 !important;
  padding-bottom: 5px !important;
}

.form-register-group {
  margin-bottom: 5px !important;
  padding-bottom: 20px !important;
}

.form-group-button {
  line-height: 40px;
  position: relative;
  font-size: 14px;
}

.form-group-register-button {
  display: flex;
  justify-content: center;
  line-height: 40px;
  position: relative;
  font-size: 14px;
  margin-top: 20px;
}

.form-input-control {
  position: relative;
  font-size: 20px;
  display: inline-block;
  width: 100%;
  height: 55px;
  color: #555 !important;
  background-color: #fff;
  background-image: none;
  border-radius: 30px !important;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.form-button-control {
  background-color: #58595b;
  border: #58595b;
  width: 100%;
  height: 55px;
  color: #fff;
  border-radius: 30px;
  font-size: 22px;
  transition: all 0.3s ease;
  margin-top: 45px;
}

.form-button-register-control {
  background-color: #58595b;
  border: #58595b;
  width: 140px;
  height: 55px;
  color: #fff;
  border-radius: 30px;
  font-size: 22px;
  transition: all 0.3s ease;
}

.form-input-error-display {
  color: #ffeb3b;
}

.form-input-success {
  border-color: #67c23a;
}

.forgot-password-input-content {
  line-height: 40px;
  position: relative;
  font-size: 14px;
}
.verify-code-btn {
  position: absolute;
  right: 5px;
  top: 5px;
  width: 84px;
  height: 53px;
  color: #1fa7d1;
  padding: 0;
  font-size: 18px;
  border: #58595b;
  border-radius: 30px;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  background: #fff;
  text-align: center;
  box-sizing: border-box;
  outline: 0;
  margin: 0;
  transition: 0.1s;
  font-weight: 500;
}
.form-link {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 50px;
}

.form-footer {
  font-size: 18px;
  margin-top: 5vh;
  color: #fff;
  text-align: center;
  line-height: 2;
  text-decoration-line: underline;
  text-decoration-thickness: initial;
  text-decoration-style: initial;
  text-decoration-color: initial;
  cursor: pointer;
}

div.form-button {
  display: flex;
  justify-content: center;
}

span .error-display {
  outline: 1px solid #cc2643 !important;
}

.el-icon-loading:before {
  content: "\E61E";
}

.el-icon-loading {
  -webkit-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}

.spinner {
  position: absolute;
}

.el-icon-circle-check-success {
  color: #67c23a;
}

a:hover {
  color: white !important;
}

.forgot-password-title {
  font-size: 1.6rem;
  text-align: center;
  color: #fff;
  margin: 2rem 0 0;
}
.forgot-password-content {
  padding: 0 30px;
  color: #fff;
  margin-top: 2rem;
  line-height: 1.7;
}

@media screen and (max-width: 1366px) {
  .form-input-control {
    height: 48px;
    font-size: 16px;
  }
  .form-button-control {
    height: 48px;
  }
  .form {
    margin-top: 40px;
  }
}

@media screen and (min-width: 764px) and (max-width: 1024px) {
  .verify-code-btn {
    height: 45px !important;
  }
}
@media screen and (min-width: 575px) and (max-width: 763px) {
  .verify-code-btn {
    height: 45px !important;
  }
}

@media screen and (min-width: 420px) and (max-width: 575px) {
  .img-logo {
    width: 350px !important;
  }
  .verify-code-btn {
    height: 45px !important;
  }
}

@media screen and (min-width: 360px) and (max-width: 420px) {
  .header {
    padding: 10vh 10vw;
  }
  .img-logo {
    width: 300px !important;
  }
  .verify-code-btn {
    height: 45px !important;
  }
}

@media screen and (min-width: 320px) and (max-width: 360px) {
  .img-logo {
    width: 250px !important;
  }
  .verify-code-btn {
    height: 45px !important;
  }
}
