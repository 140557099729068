.editFrame {
  height: 950px;
  min-height: 100%;
  background: #f6f6f6;
  text-align: center;
}

.edit-frame-header {
  position: relative;
  background: #00b2e2;
  font-size: 1.2rem;
  padding: 10px 50px;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.edit-black-frame {
  position: relative;
  display: inline-block;
  margin-right: 59px;
  font-size: 14px;
}

.edit-form {
  width: 500px;
  position: relative;
  left: 144px;
}
.edit-frame-form-item {
  margin-bottom: 0 !important;
  padding-bottom: 25px !important;
}

.edit-frame-form-label {
  text-align: right;
  float: left;
  font-size: 14px;
  color: #606266;
  line-height: 40px;
  padding: 0 12px 0 0;
  box-sizing: border-box;
}

.edit-frame-form-details {
  line-height: 40px;
  position: relative;
  font-size: 14px;
}

.form-input-control.is-valid {
  border-color: #1fa7d1 !important;
}

.edit-submit-form-button {
  width: 200px;
  border-radius: 50px;
  padding: 12px 23px;
  background-color: #00b5e2;
  border-color: #07add6;
  display: flex;
  justify-content: center;
}
.edit-remove-frame-button {
  margin-left: 40px;
  border-radius: 50px;
  width: 200px;
  padding: 12px 23px;
  color: #fff;
  background-color: #f56c6c;
  border-color: #f56c6c;
  display: flex;
  justify-content: center;
}

@media screen and (min-width: 320px) and (max-width: 425px) {
  .edit-form {
    left: 0;
    width: 270px;
    margin: 240px auto 60px;
  }
}

@media screen and (min-width: 425px) and (max-width: 500px) {
  .framebox {
    height: 200px !important;
    min-height: 100px;
    padding: 0;
  }
  .edit-form {
    left: 0;
    width: 270px;
    margin: 25px auto 60px;
  }
}

@media screen and (max-width: 800px) {
  .framebox {
    height: 300px !important;
  }
}

@media screen and (max-width: 1366px) {
  .framebox {
    height: 250px !important;
  }
  .frame {
    margin: 0 55px;
  }
  .edit-submit-form-button {
    font-size: 14px;
    width: 125px;
    padding: 10px;
  }
  .edit-remove-frame-button {
    margin-left: 5px;
    font-size: 14px;
    width: 125px;
    padding: 10px;
  }
}
